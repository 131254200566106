@import "src/styles/variables";

.restaurant-list__header{
  width: 80%;
  .back-button{
    display: inline-block;
    width: 60%;
    vertical-align: middle;
  }
}

.back-button__title{
  font-size: 18px;
  font-weight: bold;
  vertical-align: middle;
  margin-left: 15px;
}

.preferred-restaurants{
  margin-left: 10%;
}

.preferred-restaurants__title{
  font-size: 14px;
  display: inline-block;
  width: 34%;
  font-weight: 500;
  color: #000000;
}

.preferred-restaurant__cards{
  padding: 25px 0 25px 10px;
  overflow: auto;
  white-space: nowrap;
  &::-webkit-scrollbar {
    display: none;
  }
}

.preferred-restaurant__card{
  display: inline-block;
  width: 25%;
  max-width: 300px;
  margin-right: 2%;
  vertical-align: top;
  padding: 10px;
  box-shadow: 0 12px 24px #0000001F;
  border-radius: 12px;
}

.preferred-restaurant__image{
  img{
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 12px;
  }
}

.preferred-restaurant__title-cost{
  width: 90%;
  margin: 15px auto 0 auto;
}

.preferred-restaurant__title,.park-restaurant__title{
  display: inline-block;
  width: 80%;
  margin-right: 10px;
  color: #000000;
  font-weight: bold;
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.preferred-restaurant__cost,.park-restaurant__cost{
  display: inline-block;
  text-align: center;
  vertical-align: top;
  margin-top: 5px;
  color: #ffffff;
  width: 15%;
  background: $app-primary-green;
  border-radius: 4px;
  font-size: 10px;
}

.preferred-restaurant__rating,.park-restaurant__rating{
  margin-left: 5% !important;
  font-size: 12px !important;
  margin-bottom: 15px !important;
  .ant-rate-star{
    margin-right: 4px !important;
  }
  .ant-rate-star-full,.ant-rate-star-half{
    color: #FEB712 !important;
  }
  .ant-rate-star-zero .anticon{
    color: #B4B4B4;
  }
  .ant-rate-star-half .ant-rate-star-second .anticon{
    color: #B4B4B4;
  }
}

.preferred-restaurant__park,.preferred-restaurant__cuisine{
  margin-left: 5%;
  color: #000000;
  font-size: 12px;
  margin-bottom: 10px;
  white-space: pre-wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
  .icon{
    display: inline-block;
    width: 25px;
    height: 25px;
    font-size: 12px;
    color: #ffffff;
    margin-right: 5px;
    text-align: center;
    line-height: 25px;
    border-radius: 4px;
  }
}

.preferred-restaurant__park .icon{
  background: #AA29CB;
}

.preferred-restaurant__cuisine,.park-restaurant__cuisine {
  .icon {
    background: #1CE4C9;
  }
}

.park-restaurant__card{
  display: inline-block;
  vertical-align: top;
  width: 32%;
  margin: 15px 2% 15px 0;
  background: #F4F4F4;
  border-radius: 0px;
  padding: 10px;
  height:145px;
  cursor: pointer;
  &:nth-child(3n){
    margin-right: 0;
  }
}

.park-restaurant__image{
  display: inline-block;
  vertical-align: middle;
  margin-right: 5%;
  width: 30%;
  img{
    width: 100%;
    height: 120px;
  }
}

.park-restaurant__details{
  display: inline-block;
  vertical-align: middle;
  width: 65%;
}

.park-restaurant__title{
  font-size: 14px;
  font-weight: 500;
}

.park-restaurant__rating{
  margin-left: 0 !important;
}

.park-restaurant__cuisine{
  font-size: 12px;
  color: #4F4F4F;
  .icon{
    color: #ffffff;
    padding: 5px;
    border-radius: 4px;
    font-size: 14px;
    margin-right: 5px;
  }
}


.park-restaurants{
  .ant-tabs-tabpane{
    margin-top: 20px;
  }
  .ant-spin-spinning{
    margin: 50px auto !important;
    width: 100% !important;
  }
}
