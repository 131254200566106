@import "src/styles/variables";

.global-loader-icon{
  position: absolute !important;
  left: 0 ;
  right: 0;
  top:0;
  bottom:0;
  font-size: 40px !important;
  margin: auto;
  color: $primary-color !important;
}
