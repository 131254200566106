.preferences__drawer {
  .ant-drawer-content {
    padding: 2rem;
  }
  .ant-drawer-body {
      position: relative;
      overflow: hidden;
  }
  .preferences__wrapper {
    .ant-row {
      max-height: 70vh;
      overflow-y: auto;
    }
    .preferences__card {
      padding: 2rem 0 1.5rem 0;
      background-color: #eeeeee;
      border: 2px solid #eeeeee;

      border-radius: 3px;
      text-align: center;
      img {
        width: 60px;
        height: 60px;
      }
      p {
        margin-top: 1rem;
        color: #000;
      }
    }
    .preferences-card__active {
      border: 2px solid #0cbd12;
    }
  }
  .select-all-rides__checkbox {
    margin-top: 1rem;
  }

  .preferences-btn__container {
    position: absolute;
    bottom: 0;
    width: 100%;
    button {
      display: block;
      width: 100%;
      height: 50px;
      border-radius: 4px;
    }
    .preferences__save {
      background-color: #1c37e4;
      border: none !important;
      box-shadow: none !important;
      color: white;
    }
    .preferences__cancel {
      margin: 1rem 0;
      border: none !important;
      box-shadow: none !important;
    }
  }
  .ant-drawer-header {
    border: none !important;
    .ant-drawer-title {
      color: #000000;
      font-size: 24px;
      font-weight: 600;
    }
  }
  .ant-drawer-content-wrapper {
    width: 35vw !important;
  }
}
