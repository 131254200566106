.plan-weather__drawer{
  margin: auto;
  max-width: 900px!important;
}

.plan-weather{
  display: inline-block;
  vertical-align: middle;
  width: 45%;
  margin-right: 10%;
  color: #ffffff;
  &:nth-child(2){
    margin-right: 0;
  }
}

.plan-weather__section{
  background-image: url("../../../assets/weather-plan-background.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 10% 8%;
  border-radius: 7%;
}

.plan-weather__title{
  font-weight: 500;
  font-size: 22px;
}

.plan-weather__icon{
  text-align: center;
  img{
    width:55%;
  }
}

.plan-weather__place{
  font-weight: 600;
  font-size: 28px;
}

.plan-weather__date{
  font-size: 12px;
}

.plan-weather__details{
  margin-top: 10px;
}

.plan-weather__detail{
  display: inline-block;
  vertical-align: middle;
  width: 27%;
  font-size: 12px;
  &.temp{
    width: 45%;
  }
}

.plan-weather__detail__data, .plan-weather__detail sup{
  font-size: 40px;
  font-weight: 600;
}

.plan-weather__detail__desc{
  font-weight: 500;
  font-size: 16px;
}

.plan-weather__value{
  font-size: 16px;
  font-weight: 600;
}

.plan-weather__indoor{
  text-align: center;
  margin: 10px 0 10px 0;
  .ant-btn,.ant-btn:hover{
    background: #FCA120;
    color: #ffffff;
  }
}

.plan-weather__action{
  width: 100%;
  padding: 50px;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 25px;
  cursor: pointer;
  &:first-child{
    background: transparent linear-gradient(180deg, #B9FAAF 0%, #58CFC7 100%) 0 0 no-repeat padding-box;
    border-radius: 3px;
  }
  &:nth-child(2){
    background: transparent linear-gradient(180deg, #BAF2FB 0%, #12A2EA 100%) 0 0 no-repeat padding-box;
    border-radius: 3px;
  }
}

