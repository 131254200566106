.about-us{
  width: 75%;
  margin: 60px auto;
}

.about-us__column{
  display: inline-block;
  vertical-align: middle;
  width: 48%;
  &:first-child{
    margin-right: 4%;
  };
}

.about-us__title{
  color: #000000;
  font-size: 40px;
  font-weight: 600;
}

.about-us__content{
  margin: 10px 0 20px 0;
  width: 100%;
  word-break: break-word;
}

.about-us__image{
  width: 100%;
}

.about-us__action{
  font-size: 12px !important;
}

.about-us__image__mobile{
  display: none;
}

@media (max-width: 896px) {
  .about-us{
    width: 85%;
    margin: 40px auto;
  }

  .about-us__title{
    font-size: 24px;
  }

  .about-us__image__mobile{
    display: block;
    width: 100%;
    margin: 25px 0 25px 0;
  }

  .about-us__column{
    display: block;
    width: 100%;
  }

  .about-us__content{
    width: 100%;
    font-size: 16px;
    color: #000000;
  }

  .about-us__image,.about-us__action{
    display: none !important;
  }
}
