.existing-group-members__container {
  padding: 5rem;
  .existing-group-members__header {
    display: flex;
    align-items: center;
    p {
      margin: 0;
      font-size: 16px;
      color: #000;
    }
    button {
      margin-left: 2rem;
      height: 50px;
      background-color: #0cbd12;
      color: #fff;
      font-size: 16px;
      border-radius: 4px;
      box-shadow: none;
      border: none;
      padding: 0 2rem;
      .profile__placeholder {
        width: 30px;
        height: 30px;
        margin-right: 0.5rem;
      }
    }
  }
  .existing-group-members__wrapper {
    margin-top: 4rem !important;
    overflow-y: auto;
    max-height: 55vh;

    .existing-group-members__card {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.8rem 1.5rem;
      background-color: #f6f6f6;
      .d-flex {
        align-items: center;
      }
      img {
        width: 53px;
        height: 53px;
      }
      .existing-group-members-name__wrapper {
        margin-left: 1rem;
        p {
          margin: 0;
        }
        .existing-group-members__name {
          font-weight: 600;
        }
      }
    }
  }
  .existing-group-members__controllers {
    position: absolute;
    bottom: 8%;
    width: 100%;
    left: -5%;
    right: 0;
    display: flex;
    justify-content: flex-end;
    button {
      height: 50px;
      border: none;
      box-shadow: none;
    }
    .existing-group-members__cancel {
      width: 10%;
      margin-right: 1rem;
    }
    .existing-group-members__save {
      background-color: #1c37e4;
      color: #fff;
      width: 10%;
    }
  }
  .existing-group-members__checkbox {
    .ant-checkbox-inner {
      width: 34px;
      height: 34px;
      border-radius: 20px;
      border-color: #707070;
      &::after {
        top: 44% !important;
        width: 8px !important;
        height: 18px !important;
      }
    }
    .ant-checkbox,
    .ant-checkbox-checked {
      &::after {
        border: none;
      
      }
    }
  }
  .empty__text {
    text-align: center;
    margin-top: 20vh;
  }
}
