.faq{
  width: 80%;
  margin: 0 auto 50px auto;
  color: #000000;
}

.faq__title{
  font-weight: 600;
  text-align: center;
  font-size: 22px;
  margin: 50px 0;
}

.site-collapse-custom-collapse{
  background: unset !important;
}

.ant-collapse-header{
  font-weight: 500;
  font-size: 16px;
  color: #000000 !important;
  .anticon{
    font-size: 16px !important;
  }
}

@media (max-width: 896px) {
  .faq{
    display: none;
  }
}
