@import "src/styles/variables";

.rain-gear-shop{
  width: 80%;
  margin: 20px auto;
}

.rain-gear-shop__back{
  color: $primary-color;
  font-size: 25px;
  .anticon{
    cursor: pointer;
  }
}

.rain-gear-shop__section{
  display: inline-block;
  vertical-align: top;
  width: 48%;
  margin-right: 4%;
  &:last-child{
    margin-right: 0;
  }
}

.rain-gear-shop__name{
  color: #000000;
  font-size: 26px;
  font-weight: 600;
  margin-top: 30px;
}

.rain-gear-shop__location{
  margin-top: 10px;
  font-size: 12px;
  .icon-location{
    display: inline-block;
    background: #E4791C;
    color: #ffffff;
    border-radius: 4px;
    width: 20px;
    height: 20px;
    font-size: 12px;
    text-align: center;
    line-height: 20px;
    margin-right: 5px;
  }
}

.rain-gear-shop__desc{
  margin-top: 30px;
  font-size: 12px;
}

.rain-gear-shop__items__title{
  color: $primary-color;
  font-size: 12px;
  margin:30px 0 15px;
}

.rain-gear-shop__items__list{
  width: 75%;
  ul{
    padding: unset;
  }
  li{
    display: inline-block;
    width: 48%;
    vertical-align: middle;
    white-space: pre-wrap;
    margin: 0 4% 0 0;
    &:nth-child(2n){
      margin-right: 0;
    }
  }
  li:before {
    content: "\2022";
    font-size: 25px;
    vertical-align: middle;
    margin-right: 5px;
  }
}
