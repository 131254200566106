@import "src/styles/variables";

.weather{
  width: 80%;
  margin: 20px auto;
}

.weather__actions{
  text-align: right;
}

.weather__actions__indoor{
  margin-right: 10px;
  font-size: 12px !important;
}

.weather__actions__plan.ant-btn-primary{
  background: $app-primary-green !important;
  font-size: 12px !important;
}

.weather__landing-card{
  padding: 30px 40px;
  color: #ffffff;
  margin: 20px auto;
  background: url("../../assets/weather-background.png") no-repeat;
  background-size: cover;
}

.weather__landing-card__section{
  margin-top: 30px;
}

.weather__landing-card__title,.weather__landing-card__image,.weather__landing-card__detail{
  display: inline-block;
  vertical-align: top;
}

.weather__landing-card__title{
  width: 28%;
  font-size: 26px;
  font-weight: 500;
}

.weather__landing-card__image{
  width: 45%;
  img{
    width: 80%;
    margin-top: -100px;
  }
}

.weather__landing-card__detail{
  background: #ffffff14;
  padding: 20px;
  text-align: center;
  margin-left: 20px;
  width: 11%;
}

.weather__landing-card__detail--data,.weather__landing-card__detail--unit{
  font-weight: 500;
}

.weather__landing-card__detail--data{
  font-size: 22px;
}

.weather__landing-card__detail--unit{
  font-size: 17px;
  margin-left: 5px;
}

.weather__landing-card__temp{
  font-size: 28px;
  font-weight: 600;
  margin-top: -115px;
  text-transform: capitalize;
  span {
    font-size: 60px;
  }
  sup{
    font-size: 90px;
  }
}

.weather__landing-card__location,.weather__landing-card__forecasts{
  display: inline-block;
  vertical-align: middle;
  width: 50%;
}

.weather__landing-card__place{
  font-size: 28px;
  font-weight: 500;
}

.weather__landing-card__date{
  font-size: 16px;
}

.weather__forecasts--title{
  font-size: 16px;
  font-weight: 500;
}

.weather__forecast{
  background: #FFFFFF15;
  display: inline-block;
  vertical-align: top;
  width: 12%;
  margin-right: 2.2%;
  border-radius: 32px;
  text-align: center;
  padding: 10px 0;
  &:last-child{
    margin-right: 0;
  }
}

.weather__forecast--icon img{
  width: 70%;
}

.weather__forecast--day{
  font-size: 10px;
}

.weather__forecast--temp{
  font-size: 12px;
}
