@import "src/styles/variables";

.reservation-form__container {
  .ant-modal-content {
    background: transparent !important;
    box-shadow: none !important;
  }
  .ant-modal-body {
    text-align: center;
    padding: 0 !important;
  }
  .reservation-form__content {
    text-align: start;
    padding: 3rem !important;
    border-radius: 18px;
    background-color: #fff !important;
  }
  .reservation-form__title {
    text-align: center;
    font-weight: 500;
    font-size: 18px;
    color: #000;
  }
  .reservation-slot__timepicker {
    width: 100%;
    background: #f6f6f6 !important;
    border-radius: 3px;
    border: none;
    &:hover {
      border-color: #d9d9d9 !important;
    }
    .anticon {
      font-size: 28px;
      color: $primary-color;
      border-radius: 50%;
    }
  }
  .reservation-have-btn__container {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    button {
      height: 50px;
      margin: 1rem;
      width: 100%;
      border: none;
      box-shadow: none;
      background-color: #dddddd;
    }
    .active__btn {
      color: white;
      background-color: #0cbd12;
    }
  }
  .reservation-form__field {
    margin: 1rem;

    .label {
      margin-bottom: 1rem;
    }
    input {
      background-color: #f6f6f6;
      border: none;
      font-weight: bold;
      color: black;
      box-shadow: none;
      height: 50px;
    }
  }
  .reservation-modal__close {
    font-size: 35px;
    color: #ff4444;
    cursor: pointer;
    background: white;
    width: 35px;
    border-radius: 50%;
    margin-top: 1rem;
  }
  .reservation-form-btn__container {
    margin-top: 3rem;
    .form__save {
      margin-bottom: 1rem;
      display: block !important;
      width: 100% !important;
      height: 50px !important;
      background: #1c37e4 !important;
      color: white !important;
      border: none !important;
    }
  }
  .reservation-form-bottom__text {
    font-weight: 500;
    font-size: 18px;
    color: #000;
    text-align: center;
    margin-top: 2rem;
  }
}
