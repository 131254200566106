.alternate-button{
  background: #1CE4C9 !important;
  color: #ffffff !important;
  border-radius: 5px;
}

.ride-validation__members{
  margin: 20px 0;
  text-align: center;
  img{
    display: inline-block;
    vertical-align: top;
    width: 27%;
    margin: 10px 3%;
    border-radius: 50%;
  }
}

.ride-fast-pass__swap,.ride-fast-pass__swap:hover{
  background: #E4AB1C !important;
  color: #ffffff !important;
}
