@import "src/styles/variables";

.blogs-list{
  width: 80%;
  margin: 50px auto 30px auto;
  color: #000000;
}

.blogs__header{
  font-size: 16px;
  font-weight: 600;
}

.blog__card{
  display: inline-block;
  vertical-align: top;
  width: 31%;
  margin: 15px 3.5% 15px 0;
  cursor: pointer;
  &:nth-child(3n){
    margin-right: 0;
  }
  img{
    width: 100%;
    max-height: 300px;
    border-radius: 3px;
  }
}

.blog__card__title{
  font-size: 16px;
  font-weight: 500;
  margin: 15px 0 0 0;
}
.blog__card__author{
  font-size: 14px;
  margin:  0 0 10px 0;
}

.blog__card__read-more{
  font-size: 12px;
  font-style: italic;
  color: $primary-color;
  cursor: pointer;
}
