@import "src/styles/variables";

.auth-switch {
  position: absolute;
  top: 40px;
  right: 30px;
  font-size: 14px;
  .auth-switch__action {
    color: #1c37e4;
    cursor: pointer;
  }
}

.login-image,
.login-form,
.signup-form {
  display: inline-block;
  vertical-align: top;
  width: 48.5%;
  img {
    height: 100vh;
    width: 100%;
  }
  overflow: hidden;
}
.login-image {
  position: relative;
}

.login-image-logo {
  position: absolute;
  top: 10vh;
  width: 50% !important;
  height: auto !important;
  left: 25%;
}
.login-form,
.signup-form {
  margin-top: 20vh;
  margin-left: 1.5%;
  padding: 0 11%;
  .login-form__tag {
    color: #1c37e4;
    font-size: 16px;
  }
  .login-form__title {
    font-size: 26px;
    font-weight: bold;
    color: #000000;
  }
  .login-form__field {
    margin-top: 20px;
  }
  .ant-input {
    border-radius: 3px;
    background: #f6f6f6;
    border: unset;
    padding: 10px;
    font-weight: 600;
  }
  .login-form__options {
    margin-top: 15px;
    color: #acacac;
    font-size: 12px;
    div {
      float: right;
      cursor: pointer;
    }
    .login-form__options--checkbox {
      font-size: 12px;
      color: #acacac;
      .ant-checkbox-checked .ant-checkbox-inner {
        background: #1c37e4 !important;
      }
    }
  }
  .login-form__submit,
  .login-form__submit:hover,
  .login-form__submit:active,
  .login-form__submit:focus {
    width: 100%;
    background: #1c37e4;
    color: #ffffff;
    border-radius: 4px;
    margin-top: 50px;
    padding: 10px 0;
    height: auto;
    border: unset;
  }
}

.signup-form {
  margin-top: 75px;
}

.register {
  width: 90%;
  margin: auto;
  padding: 50px 0 0 0;
  .register-image {
    position: relative;
    display: inline-block;
    width: 30%;
    vertical-align: top;
    img {
      width: 100%;
      border-radius: 12px;
    }
    .register-image__text {
      color: #ffffff;
      font-weight: bold;
      font-size: 21px;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      height: fit-content;
      width: 75%;
      text-align: center;
    }
  }
  .ant-form {
    display: inline-block;
    margin-left: 3%;
    width: 65%;
    vertical-align: top;
    margin-top: 20px;
    .ant-carousel {
      .slick-dots {
        left: 0;
        right: unset;
        bottom: 0;
        margin-left: unset;
        margin-right: unset;
        .slick-active {
          width: 16px;
        }
        li {
          button {
            background: #1c37e4 !important;
            width: 10px;
            height: 10px;
            border-radius: 50%;
          }
        }
      }
    }
    .dots {
      width: 50%;
      display: inline-block;
      .dot {
        display: inline-block;
        width: 10px;
        height: 10px;
        margin-right: 10px;
        background: #dbdbdb;
        border-radius: 50%;
        cursor: pointer;
      }
      .active {
        background: #1c37e4;
      }
    }
    .register-form-carousel__step {
      height: 75vh;
      .title {
        font-size: 26px;
        font-weight: bold;
        color: #000;
        margin-bottom: 10px;
      }
      .inline-step {
        display: inline-block;
        width: 50%;
        vertical-align: top;
        .title {
          margin-bottom: 55px;
        }
        .ant-upload.ant-upload-select-picture-card {
          width: 175px;
          border: unset;
          > .ant-upload {
            padding: unset;
          }
        }
        .attached-image {
          width: 175px;
          height: 175px;
          border-radius: 50%;
          margin-bottom: 20px;
        }
        .upload-button {
          background: #e4791c !important;
          color: #fff !important;
          border-radius: 4px;
          outline: unset;
          border: unset;
          margin-left: 25px;
        }
        .ant-input {
          width: 100px;
        }
      }
      .question {
        font-size: 14px;
        font-weight: bold;
        color: #000;
        margin-bottom: 20px;
        margin-top: 20px;
      }
      .height-actions {
        button {
          margin-right: 10px;
          padding: 5px 45px;
          margin-bottom: 30px;
        }
        .ant-btn-primary {
          background: #0cbd12 !important;
          outline: unset;
          border: unset;
        }
        .ant-btn-text {
          background: #dedede;
          color: #a5a5a5;
        }
      }
      .preference-card {
        position: relative;
        display: inline-block;
        width: 16%;
        height: 125px;
        margin-right: 2%;
        vertical-align: top;
        background: #f6f6f6;
        text-align: center;
        padding: 25px 15px;
        margin-bottom: 20px;
        .icon-cusine {
          font-size: 50px;
        }
        img {
          margin: auto;
          width: 50px;
          height: 50px;
        }
      }
      .active {
        background: #cbffd7;
      }
      .selected {
        position: absolute;
        top: 10px;
        right: 10px;
        color: #0cbd12;
        font-size: 15px;
        font-weight: bold;
      }
      .mobility-options {
        margin-top: 10px;
        .ant-checkbox-wrapper {
          display: block;
          margin-bottom: 20px;
          //margin-left: auto;
          padding-left: 10px!important;
          font-size: 14px;
          margin-left: 0;
        }
      }
    }
    .carousel-controller {
      display: inline-block;
      text-align: right;
      width: 50%;
      button {
        padding: 5px 30px;
      }
    }
  }
}

.park-selection {
  position: relative;
  height: 100vh;
  background: transparent linear-gradient(180deg, #1c37e4 0%, #1ce4c9 100%) 0 0 no-repeat padding-box;
  margin: 0;
  width: 100%;
  display: block;
  .park-selection__bg {
    position: absolute;
    bottom: 0;
    height: 40%;
    width: 100%;
  }
  .park-selection__action {
    position: relative;
    width: 50%;
    display: block;
    margin: auto;
    color: #ffffff;
    .park-selection__action--title {
      font-size: 38px;
      font-weight: bold;
      text-align: center;
      line-height: 48px;
      padding-top: 12%;
    }
    .park-selection__action--cards {
      margin-top: 50px;
      .card {
        display: inline-block;
        width: 48%;
        vertical-align: top;
        background: #ffffff;
        color: #000000;
        padding: 10px;
        text-align: center;
        border-radius: 3px;
        cursor: pointer;
        &:nth-child(1) {
          margin-right: 4%;
        }
        img {
          width: 100%;
          margin: auto;
        }
        .card__park-name {
          margin: 20px 0;
          font-size: 14px;
          font-weight: bold;
        }
      }
    }
  }
}

.login-split-text {
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  margin: 20px 0;
}

.social-login {
  margin: 10px auto;
  width: 70%;
  text-align: center;
  .anticon {
    font-size: 30px;
    margin: 0 15px;
  }
  .kep-login-facebook {
    border-radius: 50% !important;
    padding: 0 !important;
    background: transparent;
    outline: unset;
    border: unset !important;
    .facebook-icon {
      color: #4c69ba;
    }
  }
  .google-icon {
    color: #db3236;
  }
}

.height-slider,
.height-input {
  display: inline-block;
  vertical-align: middle;
  width: 60%;
  margin-top: 15px !important;
}

.height-input {
  width: 75px !important;
  margin-left: 4% !important;
  text-align: center;
}

.height-slider,
.height-slider:hover {
  .ant-slider-handle,
  .ant-slider-track {
    background: $primary-color !important;
    border: unset;
  }
  .ant-slider-rail,
  .ant-slider-track {
    height: 6px;
  }
  .ant-slider-handle {
    width: 20px;
    height: 20px;
    margin-top: -8px;
  }
}

.register__profile-placeholder {
  width: 100%;
}
