@import "src/styles/variables";

.checklist-details{
  width: 80%;
  margin: 40px auto;
}

.checklist-subheading{
  display: inline-block;
  vertical-align: top;
  width: 32%;
  margin: 20px 2% 20px 0;
  &:nth-child(3n){
    margin-right: 0;
  }
}

.checklist-subheading__title{
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 10px;
  color: #000000;
  width: 92%;
  display: inline-block;
}

.checklist-subheading__options{
  font-size: 20px;
}

.checklist-subheading__card{
  background:#F8F8F8;
  border-radius: 0px;
  width: 100%;
  padding: 10px;
  margin: 10px 0;

}

.checklist-subheading__value{
  color: #000000 !important;
  span {
    font-size: 13px;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background: $app-primary-green;
    border: $app-primary-green;
  }
  .ant-checkbox-checked::after{
    border: $app-primary-green;
  }
}

.checklist-item__options{
  font-size: 20px;
  float: right;
  vertical-align: middle;
}

.checklist-subheading__action{
  margin:20px 0;
}

.new-subheading-modal{
  width: 50% !important;
}

.new-subheading-modal__title{
  color: $primary-color !important;
  margin-top: 10px !important;
}

.new-subheading-modal__field{
  width: 60%;
}

.new-subheading-modal__action{
  text-align: right;
  .ant-btn{
    width: 100px;
  }
}

.subheading-item-input{
  margin-bottom: 20px !important;
}

.template-item{
  display: inline-block;
  width: 40%;
  margin: 5px 0 !important;
}

.strike-through{
  text-decoration: line-through;
}
