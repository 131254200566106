.features{
  padding: 80px 0;
  background: transparent linear-gradient(180deg, #1C37E4 0%, #1CE4C9 100%) 0 0 no-repeat padding-box;
}

.features__title{
  color: #ffffff;
  font-size: 40px;
  font-weight: 600;
  width: 75%;
  margin: auto;
}

.features__list{
  width: 75%;
  margin: 30px auto 0 auto;
}

.features__card{
  display: inline-block;
  vertical-align: top;
  width: 18%;
  margin-right: 2.5%;
  &:last-child{
    margin-right: 0;
  }
  background: #ffffff;
  text-align: center;
  padding: 20px 0 0 0;
  box-shadow: 0 6px 25px #1C37E41A;
  border-radius: 8px;
}

.features__name{
  margin:15px 0 20px 0;
  font-size: 16px;
  font-weight: 500;
}

.feature__icon{
  width: 80%;
  text-align: center;
  margin: auto;
}

.feature__icon {
  box-shadow: 0 6px 25px #482AE40F;
  border-radius: 12px;
  padding: 10px 0;
  object-fit: contain;
}

.features__icon1{
  background: #FF82A7;
  // width: 100% !important;

}

.features__icon2{
  background: #74E6EF;
  // width: 85% !important;
}

.features__icon3{
  background: #FF977E;
  // width: 90% !important;
}

.features__icon4{
  background: #8DACFE;
  // width: 98% !important;
}

.features__icon5{
  background: #E78DFE;
  // width: 90% !important;
}

@media (max-width: 896px) {
  .features{
    padding:40px 0;
  }

  .features__title{
    width: 85%;
    font-size: 24px;
  }

  .features__list{
    width: 85%;
    text-align: center;
    margin-top: 30px;
  }

  .features__card{
    width: 45%;
    margin-right: 10%;
    margin-bottom: 30px;
    &:nth-child(2n){
      margin-right: 0;
    }
  }

  .feature__icon{
    width: 85%;
    text-align: center;
    margin: auto;
  }

  // .features__icon1,.features__icon2,.features__icon3,.features__icon4,.features__icon5{
  //   width: 90% !important;
  //   height: 125px;
  // }
}
