.landing-card__content {
  padding: 40px 50px 20px 50px !important;
  .landing-card__content-details {
    margin-top: 0 !important;
  }
}
.plan-edit__icon {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 15px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 15px;
  margin-bottom: 10px;
  img {
    width: 13px;
    height: 13px;
  }
}
.plan-edit__wrapper {
  display: flex;
  align-items: center;
}

.plan-parks {
  width: 80%;
  margin: 25px auto;
}

.plan-parks__name {
  font-weight: 700;
  color: #000000;
  font-size: 15px;
  img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    vertical-align: bottom;
  }
}

.plan-items {
  position: relative;
  display: inline-block;
  width: 32%;
  vertical-align: top;
  background: #f8f8f8;
  border-radius: 3px;
  padding: 10px;
  margin: 20px 2% 20px 0;
  &:nth-child(3n) {
    margin-right: 0;
  }
}

.plan-items__image {
  display: inline-block;
  width: 28%;
  height: 150px;
  margin-right: 2%;
  vertical-align: middle;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.plan-items__detail {
  display: inline-block;
  width: 65%;
  margin-left: 3%;
  vertical-align: middle;
}

.plan-items__detail__name {
  font-weight: 600;
  font-size: 15px;
  margin-bottom: 15px;
  color: #000000;
}

.plan-items__detail__wait {
  color: #ffffff;

  border-radius: 20px;
  padding: 3px 15px;
  width: fit-content;
  margin-bottom: 15px;
  font-size: 12px;
  margin-left: 5px;
}
.wait__green {
  background: #0cbd12;
}
.wait__blue {
  background: #1c37e4;
}
.wait__red {
  background: #e41c1c;
}
.plan-items__detail__fastpass {
  color: #e4791c;
  font-size: 12px;
  margin: 10px 0;
}
.plan-parks__empty {
  text-align: center;
  margin-top: 20vh;
}
.plan-parks_empty__explore {
  margin: auto;
  width: 30%;
}
.plan-details-empty-btn__size {
  margin-top: 5vh;
  height: 10vh;
  padding: 3.5vh;
  color: #ffffff;
}
.plan-parks-empty__info {
  font-size: 17px;
}
