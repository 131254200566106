.search-result__card{
  width: 32% !important;
  max-width: unset !important;
  margin-bottom: 20px;
  margin-top: 20px;
  &:nth-child(3n){
    margin-right: 0 !important;
  }
}

.restaurant__card__action{
  float: right;
}

.search-card__cuisine{
  width: 56%;
  display: inline-block;
}
