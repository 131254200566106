.details{
  width: 80%;
  margin: 30px auto;
  .details__greetings,.details__location,.details__search{
    display: inline-block;
  }
  .details__greetings{
    width: auto;
    color:#1C37E4;
    font-size: 14px;
    margin-right: 2%;
    vertical-align: middle;
  }
  .details__search{
    width: 20% !important;
    float: right!important;
    margin: unset !important;
  }
  .details__filter{
    float: right !important;
    margin-left: 15px !important;
  }
  .details__location{
    width: 200px;
    margin-top: 5px;
    .ant-select-selector{
      background: #F1F1F1 !important;
      font-size: 13px !important;
      border: unset !important;
    }
  }
}

.plans{
  width: 80%;
  margin: 0 auto 50px auto;
  color: #000000;
  .plans__title{
    font-weight: bold;
    font-size: 30px;
    margin-bottom: 30px;
  }
  .plans__card--main{
    padding: 50px 40px;
    width: 100%;
    border-radius: 12px;
    background-image: url("../../assets/my-plans.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    .plans__card--main-title{
      font-weight: 600;
      font-size: 18px;
      margin-bottom: 200px;
    }
    .plans__card--main-icon{
      width: 10%;
      margin: 30px auto;
      img{
        width: 80%;
      }
    }
    .plans__card--main-action{
      width: 100%;
      margin-top: 200px;
      text-align: right;
      .create-plan{
        background: #ffffff !important;
        color: #000000 !important;
        box-shadow: 0 12px 24px #0000001A !important;
        width: 200px;
        height: 50px;
        font-size: 15px;
        font-weight: 500;
      }
    }
  }

  

  .card-bg__category{
    padding: 15px;
    box-shadow: 0 6px 25px #1C37E41A;
    display: inline-block;
    width: 18%;
    margin-top: 40px;
    //border-radius: 12px;
    text-align: center;
    border-radius: 12px;
    
    cursor: pointer;
    &:not(:last-child){
      margin-right: 2.5%;
    }
    .plans__card--category {
      border-radius: 12px;
    }
    .plans__card--category-title{
      font-size: 16px;
      font-weight: 500;
      margin: 10px 0;
     
    }
    .food,.weather-card,.ride,.checklist,.help{
     // border-radius: 12px;
      padding: 40px;
      height: 12vw;
      img{
        width: 80%;
      }
    }
    .food{
      background: #F88CFC;
    }
    .weather-card{
      background: #8DACFE;
    }
    .ride{
      background: #EF997A;
    }
    .checklist{
      background: #7A7EE2;
    }
    .help{
      background: #80DFA0;
    }
  }
}

.card-bg{
  padding: 20px;
  box-shadow: 0 6px 25px #1C37E41A;
  border-radius: 12px!important;
  cursor: pointer;
}
