.plan-edit-form__container {
  .ant-modal-content {
    background: transparent !important;
    box-shadow: none !important;
  }
  .ant-modal-body {
    text-align: center;
    padding: 0 !important;
  }
  .plan-edit-form__content {
    text-align: start;
    padding: 3rem !important;
    border-radius: 18px;
    background-color: #fff !important;
  }
  .plan-edit-form__title {
    font-size: 24px;
    font-weight: bold;
    color: #1c37e4;
  }
  .plan-edit-form__field {
    margin-top: 2rem;

    .label {
      margin-bottom: 1rem;
    }
    input {
      background-color: #f6f6f6;
      border: none;
      font-weight: bold;
      color: black;
      box-shadow: none;
      height: 50px;
    }
  }
  .plan-edit-modal__close {
    font-size: 35px;
    color: #ff4444;
    cursor: pointer;
    background: white;
    width: 35px;
    border-radius: 50%;
    margin-top: 1rem;
  }
  .plan-edit-form-btn__container {
    margin-top: 2.5rem;
    .form__save {
      margin-bottom: 1rem;
      display: block !important;
      width: 100% !important;
      height: 50px !important;
      background: #1c37e4 !important;
      color: white !important;
      border: none !important;
    }
    .form__cancel {
      display: block !important;
      width: 100% !important;
      height: 50px !important;
      background: transparent !important;
      border: none !important;
      box-shadow: none !important;
    }
  }
}
