.blog-detail{
  width: 80%;
  margin: 20px auto;
}

.blog-detail__landing-card{
  background: transparent linear-gradient(117deg, #1C37E4 0%, #1CE4C9 100%) 0% 0% no-repeat padding-box;
  color: #ffffff;
  padding: 40px;
  margin-bottom: 40px;
}

.blog-detail__landing-content{
  display: inline-block;
  width: 70%;
  vertical-align: top;
}

.blog-detail__back-button{
  font-size: 25px;
}

.blog-detail__title{
  font-weight: 600;
  font-size: 26px;
  margin-top: 30px;
}

.blog-detail__landing-image{
  display: inline-block;
  width: 30%;
  vertical-align: middle;
  img{
    width: 100%;
  }
}

.ck-editor__top{
  display: none;
}

.ck.ck-editor__main>.ck-editor__editable{
  border: unset;
}

.blog-detail__content{
  margin: 40px 0;
}
