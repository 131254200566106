@import "src/styles/variables";

.search-results__header{
  width: 75%;
  margin: 20px auto;
}

.search-results_title{
  display: inline-block;
  width: 65%;
  vertical-align: middle;
  font-size: 28px;
  font-weight: 600;
  color: $primary-color;
}

.search-results__search{
  display: inline-block;
  width: 25%;
  vertical-align: middle;
  margin-right: 20px;
  .details__search{
    width: 100%!important;
  }
}

.results{
  width: 75%;
  margin: 20px auto;
}

.results__title{
  display: inline-block;
  width: 50%;
  font-size: 20px;
  font-weight: 600;
  color: #000000;
}

.results__current-plan{
  display: inline-block;
  width: 50%;
  text-align: right;
  color:#000000;
  font-size: 14px;
}

.results__current-plan__action{
  margin-left: 10px;
}
