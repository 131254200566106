.plan-categories__content{
  padding: 60px 50px 80px 50px !important;
}

.plan-categories__cards{
  margin: 30px 0;
}

.plan-categories__card{
  display: inline-block;
  vertical-align: top;
  width: 15%;
  background: #F6F6F6;
  border-radius: 0px;
  color: #000000;
  text-align: center;
  margin: 15px 2% 15px 0;
  padding:15px;
  height: 160px;
  font-size: 15px;
  font-weight: 500;
  &:nth-child(6n){
    margin-right: 0;
  }
  img{
    width: 75px;
    height: 75px;
    margin-bottom: 15px;
  }
}
