@import "src/styles/variables";

.attraction-filter__drawer {
  .ant-drawer-content{
    border-radius: 8px;
    padding: 20px 40px;
    overflow: hidden;
    background: transparent linear-gradient(180deg, #BAF2FB 0%, #12A2EA 100%) 0 0 no-repeat padding-box;
  }
  .ant-drawer-content-wrapper{
    margin-top: 5vh;
    height: 88vh !important;
    width: 80% !important;
    margin-right: 10%;
  }
  .ant-drawer-mask{
    background: rgba(0,0,0,0.9);
  }
}
.attraction-filter-options__wrapper {
  display: flex;
  justify-content: space-between;
  margin-right: 15px;
}
.attraction-filter__bg{
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  opacity: 0.4;
  z-index: -1;
  img{
    width: 100%;
    opacity: 0.5;
  }
}

.attraction-filter__title{
  position: relative;
  font-weight: 600;
  font-size: 24px;
  color:#000000;
}

.attraction-filter__tabs {
  .ant-tabs {
    .ant-tabs-nav{
      position: absolute;
      top: 45px;
      left: 30%;
    }
    .ant-tabs-nav, .ant-tabs-content-holder {
      width: 100%;
      margin: auto;
    }

    .ant-tabs-nav::before {
      border: unset !important;
    }

    .ant-tabs-tab {
      background: #ffffff;
      padding: 8px 50px;
      border-radius: 3px;
      font-size: 12px !important;

      .ant-tabs-tab-btn {
        color: #000000;
      }
    }

    .ant-tabs-ink-bar {
      width: 0 !important;
    }

    .ant-tabs-tab-active {
      background: $app-primary-green;

      .ant-tabs-tab-btn {
        color: #ffffff !important;
      }
    }
  }
}

.attraction-filter__block{
  display: inline-block;
vertical-align: top;
padding: 17px;
background: #ffffff;
border-radius: 12px;
box-shadow: 0 12px 24px #00000014;
margin-top: 30px;
margin-right: 1.25%;
  &.parks{
    width: 20%;
    // padding-top: 18px;
    // padding-right: 8px;
    // padding-bottom: 18px;
    // padding-left: 14px;
  }
  &.parks1{
    width:25%;
  }
  &.themes{
    width: 18%;
  }
  &.experiences{
    width: 22.7%;
    // padding-top: 18px;
    // padding-right: 8px;
    // padding-bottom: 18px;
    // padding-left: 14px;
  }
  &.mobility{
    width: 16%;
    margin-right: 0;
  }
  &.cuisines{
    width: 27.5%;
  }
}

.attraction-filter__block__title{
  font-weight: 600;
  font-size: 16px;
  color: #000000;
  margin-bottom: 10px;
}

.attraction-filter__options{
  margin-bottom: 13px;
  margin-left : 6px;
  // margin-right: 6px;
  display: flex;
  justify-content: space-between;
  div {
    width: 80%;
  }
  span {
    font-weight: 400;
    font-size: 12px !important;
    color: #000000;
  }
}

// .checkbox_space{
//   padding-left: 10px;
//   width: 0px;
// }

.attraction-filter__option-icon{
  width:20px;
  height:20px;
  padding-right: 5px;
}

.cuisine-options{
  // display: inline-block;
  // vertical-align: top;
  display: flex;
  justify-content: space-between;
}

.attraction-filter__actions{
  text-align: right;
  margin-top: 10px;
  margin-right: 20px;
  .ant-btn,.clear-filter__action{
    color: #ffffff;
    width: 100px;
    font-size: 12px;
    &:hover,&:active{
      color: #ffffff;
    }
  }
  .clear-filter__action{
    float: left;
  }
}
