@import 'src/styles/variables';

.ride-details{
  width: 85%;
  margin: 20px auto;
  position: relative;
}

.ride-details__basic,.ride-details__carousel{
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 48%;
  margin-right: 2%;
}

.back-button{
  color:$primary-color;
  font-size: 25px;
  .anticon {
    cursor: pointer;
  }
}

.ride-details__header{
  margin: 30px 0 15px 0;
}

.ride-details__name{
  display: inline-block;
  vertical-align: top;
  width: 70%;
  margin-right: 2%;
  color:#000000;
  font-size: 25px;
  font-weight: bold;
}

.ride-details__wait{
  display: inline-block;
  vertical-align: middle;
  width: 125px;
  text-align: center;
  padding: 5px;
  background: $app-primary-green;
  border-radius: 4px;
  color: #ffffff;
  span {
    vertical-align: middle;
    &:nth-child(2) {
      margin-left: 10px;
    }
  }
 
}
.ride-wait__green {
  background: #0cbd12;
}
.ride-wait__blue {
  background: #1c37e4;
}
.ride-wait__red {
  background: #e41c1c;
}

.ride-details__tags{
  margin: 15px 0;
}

.ride-details__tag{
  display: inline-block;
  vertical-align: top;
  margin: 0 10px 0 0;
  background: #E4AB1C;
  color: #ffffff;
  font-size: 10px;
  border-radius: 16px;
  padding: 5px 10px;
}

.ride-details__description{
  font-size: 13px;
  color: #000000;
}

.ride-details__restrictions{
  margin: 25px 0;
}

.ride-details__restriction{
  display: inline-block;
  vertical-align: top;
  width: 45%;
  margin-right: 3%;
  margin-bottom: 15px;
  font-size: 13px;
  span{
    width: 25px;
    height: 25px;
    text-align: center;
    padding: 6px;
    color: #ffffff;
    border-radius: 4px;
    margin-right: 5px;
    display: inline-block;
  }
  .duration{
    background: #2ABEBE;
  }
  .height{
    background: #E4791C;
  }
  .park-name{
    background: #AA29CB;
  }
  .single{
    background: #14BDFD;
  }
  .fastpass{
    background: #22B001;
  }
  .swap{
    background: #482AE4;
  }
  .when{
    background: #6B2CFF;
  }
}

.ride-details__action{
  width: 65%;
  .ant-btn-primary,.change-plan-button{
    width: 100% !important;
  }
}

.ride-details__carousel{
  width: 49%;
  margin-right: 0;
  img{
    width: 100%;
    max-height: 80vh;
  }
  .ant-carousel .slick-dots li button {
    background: #AFAFAF;
    opacity: 0.4;
    height: 12px;
    width: 12px;
    border-radius: 50%;
  }

  .ant-carousel .slick-dots li.slick-active {
    width: 16px !important;
  }
}

.photopass-map{
  width: 85%;
  margin: 15px auto;
}

.photopass-map__title{
  color: #000000;
  font-size: 22px;
  margin-bottom: 10px;
}

.photopass-map__card{
  border-radius: 0px;
  background: #F6F6F6;
}

.photopass-map__image{
  img {
    max-height: 300px;
    width: 100%;
    border-radius: 4px;
  }
}

.photopass-map__content,.photopass-map__image{
  display: inline-block;
  vertical-align: top;
  width: 73%;
  margin: 15px auto;
}

.photopass-map__image{
  width: 25%;
  margin-left: 2%;
  img{
    width: 100%;
  }
}

.photopass-map__content__title{
  font-weight: bold;
  font-size: 24px;
  color: #000000;
  margin-bottom: 10px;
}

.photopass-map__content__desc{
  font-size: 14px;
  height: 175px;
  padding-bottom: 10px;
}

.photopass-map__view{
  img {
    width: 100%;
    max-height: 525px;
  }
}

.photopass-map__placeholder{
  font-size: 22px;
  color: #000000;
  text-align: center;
  margin-top: 100px;
}

.hidden-mickeys{
  width: 85%;
  margin: 20px auto;
}

.hidden-mickeys__header{
  font-size: 22px;
  margin-bottom: 15px;
}

.hidden-mickeys__content{
  display: inline-block;
  vertical-align: middle;
  width: 49%;
  margin-right: 2%;
  &.image{
    vertical-align: top;
    margin-right: 0;
    img{
      width: 100%;
    }
  }
}

.hidden-mickeys__title{
  font-size: 26px;
  font-weight: bold;
  color: #000000;
  margin-top: 30px;
}

.hidden-mickeys__description{
  font-size: 14px;
  margin-top: 10px;
}

.accessibility{
  width: 80%;
  margin: 20px auto;
  color: #000000;
}

.accessibility__header{
  margin: 20px 0;
  font-size: 22px;
}

.accessibility__list{
  margin: 30px 0;
  li{
    margin-bottom: 15px;
    text-transform: capitalize;
  }
}
