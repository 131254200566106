@import "src/styles/variables";

.blog-filter__header{
  width: 80%;
  margin: 30px auto;
}

.blog-filter__back-button{
  color: $primary-color !important;
  font-size: 25px;
  margin-bottom: 40px;
}

.blog-filter__card{
  box-shadow: 0 12px 24px #00000014;
  border-radius: 0px;
  padding: 30px;
  color: #ffffff;
  img{
    width: 12%;
    margin: 0 30px;
  }
}

.blog-filter__title{
  display: inline-block;
  vertical-align: middle;
  font-weight: 600;
  font-size: 23px;
}

