.privacy-policy{
  margin: 40px auto;
  width: 60%;
  max-width: 800px;
  white-space: pre-wrap;
  word-break: break-word;
  color: #000000;
  padding-bottom: 40px;
}

.privacy-policy__header{
  text-align: center;
}

h2{
  color: #000000 !important;
}

.privacy-policy__content{
  margin-bottom: 20px;
}
