@import "src/styles/variables";

.landing__section-1{
  position: relative;
  margin-top: 63px;
  background: url("../../../assets/landing-page-assets/section1-background.png") no-repeat center;
  background-size: cover;
  color: #ffffff;
}

.landing__section-1__title{
  text-align: center;
  font-size: 60px;
  font-weight: 600;
  padding-top: 50px;
}

.landing__section-1__tag{
  text-align: center;
  width: 50%;
  margin: 10px auto;
  font-size: 18px;
}

.landing__section-1__action{
  margin: 30px 0;
  text-align: center;
  .ant-btn{
    height: 40px;
    font-size: 12px !important;
  }
}

.landing__section-1__get-started{
  background: $secondary-color !important;
  color: #ffffff !important;
  font-weight: 500 !important;
  margin-right: 15px;
}

.landing__section-1__image{
  text-align: center;
  img{
    width: 45%;
  }
}

.landing__section-1__app-stores{
  position: absolute;
  bottom: -15px;
  right: 5%;
  text-align: right;
}

.landing__section-1__google-badge{
  width: 22%;
  cursor: pointer;
}
.landing__section-1__apple-badge{
  width: 18%;
  cursor: pointer;
}

.landing__section-1__icons{
  position: absolute;
  width: 8%;
}

.landing__section-1__icon1{
  bottom: 35%;
  left: 20%;
}

.landing__section-1__icon2{
  bottom: 28%;
  left: 12%;
}

.landing__section-1__icon3{
  bottom: 10%;
  left: 7%;
}

.landing__section-1__icon4{
  bottom: 12%;
  left: 17%;
}

.landing__section-1__icon5{
  bottom: 36%;
  right: 17%;
}

.landing__section-1__icon6{
  bottom: 30%;
  right: 10%;
  width: 5%;
}

.landing__section-1__icon7{
  bottom: 16%;
  right: 18%;
}

.landing__section-1__icon8{
  bottom: 11%;
  right: 8%;
}

.landing__section-1__mobile-logo,
.landing__section-1__action__mobile,
.landing__section-1__image__mobile{
  display: none;
}


@media (max-width: 896px) {
  .landing__section-1 {
    margin-top: 0;
    padding-bottom: 5px;
  }

  .landing__section-1__mobile-logo{
    display: block;
    width: 50%;
    margin: auto;
    padding-top: 40px;
    img{
      width: 100%;
    }
  }

  .landing__section-1__title{
    font-size: 26px;
    padding-top: 30px;
  }

  .landing__section-1__tag{
    font-size: 14px;
    width: 65%;
  }

  .landing__section-1__action,
  .landing__section-1__app-stores,
  .landing__section-1__image{
    display: none;
  }

  .landing__section-1__action__mobile{
    display: block;
    width: 80%;
    margin: 30px auto 0 auto;
    .landing__section-1__get-started{
      display: block;
      margin-right: 0;
      font-size: 16px;
      width: 100% !important;
      height: 50px;
      line-height: 45px !important;
    }
  }

  .landing__section-1__image__mobile{
    display: block;
    position: relative;
    width: 90%;
    margin: 80px auto 50px auto;
    z-index: 1;
    img{
      width: 100%;
    }
  }

  .landing__section-1__icon1{
    display: none;
  }
  .landing__section-1__icon2{
    width: 27%;
    top: 44%;
    left: -3%;
  }
  .landing__section-1__icon3{
    width: 30%;
    top: 68%;
    left: -4%;
  }
  .landing__section-1__icon4{
    width: 15%;
    bottom:2%;
    left: unset;
    right: 1%;
    transform: rotateZ(-10deg);
  }
  .landing__section-1__icon5{
    width: 25%;
    top:45%;
    left: unset;
    right: -1%;
  }
  .landing__section-1__icon6{
    width: 17%;
    top:70%;
    left: unset;
    right: 0;
  }
  .landing__section-1__icon7{
    width: 20%;
    bottom:2%;
    left: 6%;
  }
  .landing__section-1__icon8{
    width: 25%;
    bottom:1.25%;
    left: unset;
    right: 25%;
  }
}
