@import "variables";
@import "helpers";
@import url('https://fonts.googleapis.com/css?family=Jost:200,400,500,600,700,900');

//font
@font-face {
  font-family: "JostThin";
  src: url('../assets/fonts/Jost/static/Jost-Thin.ttf') format('truetype');
}

@font-face {
  font-family: "JostRegular";
  src: url('../assets/fonts/Jost/Jost-Regular.woff') format('woff'),
  url('../assets/fonts/Jost/Jost-Regular.ttf') format('truetype');
}

@font-face {
  font-family: "JostVariableFont";
  src: url('../assets/fonts/Jost/Jost-VariableFont_wght.woff') format('woff'),
  url('../assets/fonts/Jost/Jost-VariableFont_wght.ttf') format('truetype');
}

@font-face {
  font-family: "JostVariableFontItalic";
  src: url('../assets/fonts/Jost/Jost-Italic-VariableFont_wght.woff') format('woff'),
  url('../assets/fonts/Jost/Jost-Italic-VariableFont_wght.ttf') format('truetype');
}

/* General component styles */
body {
  background-color: #FFFFFF;
  color: $primary-black;
}

body, h1, h2, h3, h4, h5, button, input {
  font-family: $app-primary-font !important;
}


.label{
  color: #ACACAC;
  font-size: 12px;
}

.ant-btn-primary{
  background: $primary-color !important;
  outline: unset;
  border: unset;
  font-weight: 500;
}

.user-component{
  display: inline-block;
  margin-top: 100px;
  width: 100%;
}

.ant-input-search{
  //display: block;
  width: 40% !important;
  right: 5%;
  vertical-align: middle !important;
  margin: 20px 0;
  z-index: 1;
  box-shadow: 0 3px 8px #0000001A;
  .ant-input{
    border: unset;
    padding: 10px 20px;
  }
  .ant-input-search-button{
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 10px;
    padding: 5px 7px;
    background: $primary-color;
    border-radius: 4px !important;
    z-index: 1;
    .anticon{
      font-size: 15px;
    }
  }
}


.delete-modal{
  width: 30% !important;

  .delete-modal__text{
    text-align: center;
    font-weight: bold;
    font-size: 15px;
  }

  .delete-modal__actions{
    margin-top: 30px;
    margin-bottom: 5px;
    text-align: right;
  }

  .ant-btn-primary,.ant-btn-primary:hover,.ant-btn-primary:focus{
    border: unset;
    background: $danger-color !important;
    font-size: 12px;
  }
}

.landing-card{
  width: 80%;
  margin: auto;
  margin-top: 3rem;
  background: transparent linear-gradient(101deg, #1C37E4 -50%, #1CE4C9 100%) 0 0 no-repeat padding-box;
  color: #ffffff;
  border-radius: 10px;
  .landing-card__content{
    width: 60%;
    padding: 60px 50px 0 50px;
    .landing-card__content-title{
      font-weight: 600;
      font-size: 25px;
      margin-bottom: 10px;
    }
    .landing-card__content-details{
      font-size: 14px;
      margin-top: 15px;
    }
    .landing-card__content-back{
      font-size: 30px;
      margin-bottom: 15px;
      cursor: pointer;
      border-radius: 15px !important;
    }
    .landing-card__content-count{
      margin-top: 10px;
    }
  }
  .landing-card__action {
    text-align: right;
    margin-right:30px;
    padding-bottom: 30px;
    .landing-card__action--button {
      //background: #E4791C !important;
      border-radius: 3px !important;
      font-size: 13px;
      box-shadow: unset !important;
      font-weight: 500;
    }
  }
}

.manage-groups,.manage-groups-users,.manage-plans,.park-restaurants,.plan-categories__meta{
  width: 85%;
  margin: 40px auto;

  .ant-tabs{
    .ant-tabs-nav,.ant-tabs-content-holder{
      width: 94%;
      margin: auto;
    }
    .ant-tabs-content-holder{
      margin-bottom: 30px;
    }
    .ant-tabs-nav::before{
      border: unset !important;
    }
    .ant-tabs-tab{
      background: #EEEEEE;
      padding: 8px 30px;
      border-radius: 3px;
      font-size: 12px !important;
      font-weight: 400;
      .ant-tabs-tab-btn{
        color:#404040;
      }
    }
    .ant-tabs-ink-bar{
      width: 0 !important;
    }
    .ant-tabs-tab-active{
      background: $primary-color;
      font-weight: 600;
      .ant-tabs-tab-btn{
        color: #ffffff !important;
      }
    }
  }
}

.filters-button{
  background: $secondary-color !important;
  font-size: 13px;
  height: 40px !important;
  font-weight: 500;
  white-space: pre-wrap;
}


.block-loader{
  text-align: center;
  margin: 20px auto;
  width: 100%;
}

.ant-btn-primary[disabled]{
  color: #ffffff !important;
}
