@import "src/styles/variables";

.ride-fast-pass__title{
  font-weight: 500;
  color: #000000;
  font-size: 16px;
  margin: 25px 0;
}

.ride-fast-pass__selection{
  .ant-btn{
    display: inline-block;
    vertical-align: top;
    width: 48%;
    &:first-child{
      margin-right: 4%;
    }
  }
  .ant-btn-text,.ant-btn-text:hover{
    background: #DDDDDD;
    color: #ADADAD;
  }
  .active{
    background: $app-primary-green !important;
    color:#ffffff !important;
  }
}

.ride-fast-pass__time-slot{
  margin: 30px 0
}

.ride-fast-pass__label{
  color:#ACACAC;
  font-size: 12px;
  margin-bottom: 5px;
}

.ride-fast-pass__timepicker{
  width: 100%;
  background: #F6F6F6 !important;
  border-radius: 3px;
  &:hover{
    border-color: #d9d9d9 !important;
  }
  .anticon{
    color: #ffffff;
    background: $primary-color;
    border-radius: 50%;
  }
}

.ride-fast-pass__action{
  margin: 25px 0;
  .ant-btn{
    width: 100%;
  }
}
