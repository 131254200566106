.blogs__landing-card{
  width: 80%;
  margin: auto;
  background: transparent linear-gradient(118deg, #1C37E4 0%, #1CE4C9 100%) 0 0 no-repeat padding-box;
  border-radius: 0px;
  padding: 20px 40px;
  color: #ffffff;
}

.blogs__landing-card__title{
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 15px;
  span{
    font-size: 12px;
    font-weight: 400;
  }
}

.blogs__landing-card__types{
  display: inline-block;
  vertical-align: top;
  border-radius: 12px;
  text-align: center;
  width: 32%;
  margin-right: 2%;
  box-shadow: 0 12px 24px #00000014;
  padding: 30px 0;
  cursor: pointer;
  &:nth-child(3){
    margin-right: 0;
  }
  img{
    width: 60%;
  }
}

.create-a-character{
  background: transparent linear-gradient(180deg, #BAF2FB 0%, #12A2EA 100%) 0 0 no-repeat padding-box;
}

.design-castle{
  background: transparent linear-gradient(180deg, #FFEEAD 0%, #FF6161 100%) 0 0 no-repeat padding-box;
}

.ride-designer{
  background: transparent linear-gradient(180deg, #B9FAAF 0%, #58CFC7 100%) 0 0 no-repeat padding-box;
}

.blogs__landing-card__tag-name{
  font-weight: 600;
  font-size: 16px;
  margin-top: 15px;
}
