@import "src/styles/variables";

.pending-invite__modal{
  text-align: center;
}

.pending-invite__member{
  width: 100%;
  margin: 20px 0;
  img{
    width: 40%;
    border-radius: 50%;
  }
}

.pending-invite__name{
  color:#000000;
  font-weight: 500;
  font-size: 16px;
}

.pending-invite__detail{
  color:#000000;
  font-size: 13px;
  span{
    font-weight: 500;
    color: #1C37E4;
  }
}

.pending-invite__action{
  margin:20px 0;
  .ant-btn{
    width: 100%;
    margin-bottom: 10px;
  }
  .ant-btn-primary{
    background: $app-primary-green !important ;
  }
  .ant-btn-text,.ant-btn-text:hover{
    color:#B4B4B4;
  }
}
