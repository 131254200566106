.plan-form-drawer{
  .ant-drawer-content-wrapper{
    width: 60% !important;
    height: 80vh !important;
    max-height: 700px !important;
    margin-right: 20%;
    margin-top: 7vh;
  }
  .ant-drawer-body {
    overflow-x: hidden !important;

  }
  .ant-drawer-content{
    border-radius: 8px;
    padding: 20px 40px;
  }
  .ant-drawer-header{
    display: none;
  }
  .ant-drawer-mask{
    background: rgba(0,0,0,0.9);
  }
}

.plan-form__title{
  font-weight: 700;
  font-size: 22px;
  margin-bottom: 20px;
  color: #000000;
}

.plan-form__field{
  display: inline-block;
   width: 100%;
  margin: 15px 6% 15px 0;
  vertical-align: top;
  &:nth-child(2n){
    margin-right: 0;
  }
}

.plan-form__label{
  color:#ACACAC;
  font-size: 12px;
  margin-bottom: 10px;
}

.plan-form__input,.plan-form__picker{
  background-color: #F6F6F6 !important;
  color: #313131 !important;
  border-radius: 3px !important;
  outline: unset !important;
  border: unset !important;
  height: 50px !important;
  font-weight: 600 !important;
  &:focus,&.ant-picker-focused{
    box-shadow: unset !important;
  }
}

.plan-form__picker{
  width: 100%;
  .ant-picker-input > input{
    font-weight: 600 !important;
    color: #313131 !important;
  }
}

.plan-form__label--bold{
  font-weight: 700;
  font-size: 16px;
  color: #000000;
  margin-bottom: 15px;
}

.plan-form__group-card{
  position: relative;
  display: inline-block;
  width: 32%;
  vertical-align: top;
  text-align: center;
  margin-right: 2%;
  background: #F6F6F6;
  border-radius: 0px;
  padding: 25px 10px;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  color: #000000;
  &:nth-child(3){
    margin-right: 0;
  }
  img{
    width: 130px;
    height: 130px;

    margin-bottom: 10px;
  }
  &.active{
    background: #CBFFD7;
  }
  .anticon-check-circle {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 15px;
    color: #0CBD12;
  }
}

.crowd-calendar-details{
  box-shadow: 0 12px 24px #00000014;
  border-radius: 4px;
  padding: 15px;
}

.crowd-calendar__title{
  font-size: 12px;
  color:#1C37E4;
  text-align: center;
  margin-bottom: 20px;
  font-weight: 600;
}

.crowd-calender__field{
  width: 85%;
  margin: 10px auto;
}

.crowd-calendar__name{
  display: inline-block;
  vertical-align: middle;
  width: 75%;
  margin-right: 2%;
  font-size: 13px;
  color: #000000;
  &.location-name{
    font-weight: bold;
  }
}

.crowd-calendar__expectation{
  display: inline-block;
  vertical-align: top;
  width: 20%;
  color: #ffffff;
  border-radius: 4px;
  padding: 5px;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
}

.crowd-green{
  background: #0CBD12;
}

.crowd-orange{
  background: #FCA120;
}

.crowd-red{
  background: #FF4444;
}

.plan-form__park-icon{
  width: 20px;
  height: 20px;
  vertical-align: bottom;
}
.plan-form-add__group-selector {
  width: 100%;
  margin: 15px auto;
  text-align: center;
  .ant-select-selection-item,.ant-select-selection-placeholder {
    margin-top: 10px;
  }

  .ant-select{
    width: 100%;
  }
  .ant-select-selector{
    background-color: #F6F6F6 !important;
    color: #313131 !important;
    border:unset !important;
    outline: unset!important;
    box-shadow: unset !important;
    font-weight: 500;

  }
}
.plan-form__group-selector{
  width: 80%;
  margin: 15px auto;
  text-align: center;
  .ant-select{
    width: 100%;
  }
  .ant-select-selector{
    background-color: #F6F6F6 !important;
    color: #313131 !important;
    border:unset !important;
    outline: unset!important;
    box-shadow: unset !important;
    font-weight: 500;
  }
}

.plan-form__creator{
  width: 100%;
  text-align: left;
  .plan-form__input{
    display: inline-block;
    width: 75%;
    margin-right: 5%;
    vertical-align: middle;
  }
  .plan-form__create-button{
    display: inline-block;
    vertical-align: middle;
    width: 20%;
    height: 48px !important;

  }
}



.plan-form__actions{
  float: right;
}
