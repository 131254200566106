.checklist__drawer {
  .ant-drawer-content {
    padding: 2rem;
  }
  .ant-drawer-body {
    position: relative;
    overflow: hidden;
  }
  .ant-drawer-content-wrapper {
    width: 80vw !important;
  }
  .ant-drawer-header {
    border: none !important;
    .ant-drawer-title {
      color: #000000;
      font-size: 24px;
      font-weight: 600;
    }
  }
  .checklist-drawer__content {
    // .radio__icon-active {
    //   width: 20px;
    //   height: 20px;
    //   border-radius: 10px;
    //   border: 2px solid #1c37e4;
    //   display: flex !important;
    //   justify-content: center;
    //   align-items: center;
    //   .radio-icon__inner {
    //     width: 10px;
    //     height: 10px;
    //     border-radius: 5px;
    //     background-color: #1c37e4;
    //   }
    // }
    .radio-icon__inactive {
      width: 15px;
      height: 15px;
      border: 1px solid #949494;
      border-radius: 10px;
    }
    .radio__icon {
        width: 15px;
        height: 15px;
    }
  }
  
  .item__checkbox {
      display: block;
      margin-left: 2rem;
      margin-bottom: 1rem;
    .ant-collapse {
      margin-right: 0.5rem;
      border: none;
    }
  }
  .panel__wrapper {
    background-color: #f8f8f8;
    border-radius: 4px;
    padding: 1rem;
    min-height: 150px;
  }
  .existing-checklist-btn__container {
    position: absolute;
    bottom: 0;
    width: 100%;
    button {
      width: 20%;
      height: 50px;
      float: right;
      border-radius: 4px;
    }
    .existing-checklist__save {
      background-color: #1c37e4;
      border: none !important;
      box-shadow: none !important;
      color: white;
    }
    .existing-checklist__cancel {
      border: none !important;
      box-shadow: none !important;
    }
  }
}

.checklist__panel {
    .ant-collapse-header {
        font-size: 18px !important;
    }
}
.subheading__panel {
    .ant-collapse-header {
        font-size: 16px !important;
    }
}
