@import "src/styles/variables";

.ride-details__header{
  margin-bottom: 0;
}

.restaurant-details__highlights{
  margin: 15px 0;
}

.restaurant-details__highlight{
  margin: 30px 0;
  color: #000000;
  font-size: 12px;
  .icon{
    color: #ffffff;
    padding: 7px;
    border-radius: 4px;
    margin-right: 5px;
  }
}

.wine .icon{
  background: #482AE4;
}

.park .icon{
  background: #AA29CB;
}

.restaurant-details__rating{
  .ant-rate-star{
    font-size: 14px;
    margin-right: 4px !important;
  }
  .ant-rate-star-full,.ant-rate-star-half{
    color: #FEB712 !important;
  }
  .ant-rate-star-zero .anticon{
    color: #B4B4B4;
  }
  .ant-rate-star-half .ant-rate-star-second .anticon{
    color: #B4B4B4;
  }
}

.restaurant-menu-nearby{
  width: 85%;
  margin: 20px auto;
}

.restaurant-menu-nearby__block{
  display: inline-block;
  vertical-align: top;
  width: 49%;
  margin: 10px 2% 10px 0;
  &:nth-child(2){
    margin-right: 0;
  }
}

.restaurant__menu__title,.restaurant__location__title{
  color: #000000;
  font-size: 22px;
  margin-bottom: 25px;
}

.restaurant__menu__list{
  position: relative;
  width: 80%;
  padding: 15px;
  border-radius: 4px;
  box-shadow: 0 3px 8px #0000001A;
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: 500;
  color: #000000;
  .ant-btn-primary{
    background: $app-primary-green !important;
  }
}

.restaurant__menu__action{
  position: absolute !important;
  right: 10px;
  top:0;
  bottom:0;
  font-size: 12px !important;
  margin:auto;
}

.restaurant__menu__places{
  background: #F6F6F6;
  border-radius: 5px;
  font-size: 14px;
  padding: 20px 0 10px 25px;
  li{
    margin-bottom: 10px;
  }
}

.restaurant__location{
  width: 85%;
  margin: 25px auto 40px auto;
}

.restaurant-details__cuisine-icon{
  margin-right: 5px;
}
