@import "src/styles/variables";

.landing__header{
  position: fixed;
  z-index: 1;
  width: 100%;
  background: $primary-color !important;
}

.landing__logo{
  display: inline-block;
  width: 12%;
  text-align: right;
  img{
    width: 80px;
  }
}

.landing__desktop-menu{
  display: inline-block;
  vertical-align: top;
  width: 80%;


  .ant-menu{
    width: 100%;
    text-align: right !important;
    background: $primary-color !important;
    color: #ffffff !important;
    line-height: 52px !important;
    border: unset!important;
  }

  .ant-menu-item{
    border: unset !important;
  }

  .ant-menu-item:hover{
    color:#ffffff !important;
  }

  .ant-menu-item-selected{
    color: #ffffff !important;
    border: unset !important;
  }
}

.landing__mobile-menu{
  display: none !important;
}


@media (max-width: 896px) {
  .landing__desktop-menu{
    display: none;
  }
  .landing__header{
    display: none;
  }
  /*.landing__mobile-menu{
    display: inline-block !important;
    float: right;
    margin-top: 12px;
    font-size: 25px;
  }*/
}
