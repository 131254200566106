@import "src/styles/variables";

.checklist-cards {
  width: 80%;
  margin: 30px auto;
}

.checklist-cards__card {
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 32%;
  margin: 20px 2% 20px 0;
  border-radius: 0px;
  box-shadow: 0 12px 25px #00000014;
  padding: 15px 40px;
  &:nth-child(3n) {
    margin-right: 0;
  }
}

.checklist-cards__card__action {
  position: absolute;
  right: 20px;
  top: 5px;
  color: #d5d5d5 !important;
  text-align: right !important;
  font-size: 30px !important;
}

.checklist-cards__name {
  font-weight: 600;
  color: #000000;
  font-size: 18px;
  white-space: pre-wrap;
}

.checklist-cards__detail {
  margin: 10px 0;
}

.checklist-cards__icon {
  display: inline-block;
  vertical-align: middle;
  width: 30px;
  height: 30px;
  padding: 6px;
  margin-right: 10px;
  text-align: center;
  background: #ffd56f;
  border-radius: 3px;
  &.date-icon {
    background: #ff82a7;
  }
}

.checklist-cards__value {
  display: inline-block;
  vertical-align: middle;
  width: calc(100% - 45px);
  font-size: 13px;
}

.new-checklist-modal__title {
  margin-top: 10px !important;
}
.checklist-form-actions__container {
  margin-top: 1rem;
  button {
    display: block;
    width: 100%;
    height: 50px;
    border-radius: 4px;
    margin-top: 1rem;
  }
  .existing__btn {
    background-color: #1CE4C9 !important;
  }
}

.checklist-plan__select {
  margin: 10px 0 !important;
  width: 100%;
}

.checklist-item-loader {
  top: 50vh;
}

.checklist-label {
  margin-top: 20px;
}
