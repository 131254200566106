.maximize-time{
  width: 75%;
  margin: 60px auto;
}

.maximize-time__section{
  display: inline-block;
  vertical-align: middle;
  width: 48%;
  &:first-child{
    margin-right: 4%;
  }
}

.maximize-time__image{
  width: 100%;
}

.maximize-time__title{
  color: #000000;
  font-weight: 600;
  font-size: 40px;
}

.maximize-time__content{
  width: 85%;
  margin: 10px 0;
}

.maximize-time__cards{
  margin-top: 20px;
}

.maximize-time__card{
  display: inline-block;
  vertical-align: top;
  width: 32%;
  margin: 25px 2% 25px 0;
  &:nth-child(3){
    margin-right: 0;
  }
}

.maximize-time__card__image{
  width: 30%;
  img{
    width: 100%;
    max-height: 50px;
  }
}

.maximize-time__icon1,.maximize-time__icon2,.maximize-time__icon3,.maximize-time__icon4,.maximize-time__icon5{
  border-radius: 8px;
  margin-bottom: 8px;
}

.maximize-time__icon1{
  background: #FFE9D5;
  padding: 8px 8px 8px 12px;
}

.maximize-time__icon2{
  background: #E2FFD5;
  padding: 11px 7px;
}

.maximize-time__icon3{
  background: #E5D6E8;
  padding: 9px;
}

.maximize-time__icon4{
  background: #CFE4F8;
  padding: 9px;
}

.maximize-time__icon5{
  background: #D5DBFF;
  padding: 7px;
}

.maximize-time__card__content{
  font-size: 13px;
  word-break: break-word;
  width: 85%;
}

@media (max-width: 896px) {
  .maximize-time{
    width: 85%;
    margin: 30px auto;
  }

  .maximize-time__image{
    display: none;
  }

  .maximize-time__section{
    width: 100%;
  }

  .maximize-time__title{
    font-size: 26px;
  }

  .maximize-time__content{
    width: 100%;
    color: #000000;
    margin-bottom: 20px;
  }

  .maximize-time__cards{
    margin-top: 0;
  }

  .maximize-time__card{
    width: 100%;
    margin: 15px 0;
  }

  .maximize-time__card__image{
    display: inline-block;
    vertical-align: middle;
    width: 18%;
    img{
      width: 100%;
      max-height: 100%;
    }
  }

  .maximize-time__card__content{
    display: inline-block;
    vertical-align: middle;
    width: 75%;
    margin-left: 5%;
    font-size: 16px;
  }
}
