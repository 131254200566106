.nav-bar,.nav-bar-items{
    position: fixed;
    background: #1C37E4 !important;
    color: #ffffff !important;
    font-size: 13px !important;
    border: unset !important;
    z-index: 99;
}

.nav-bar{
    padding: 15px 0;
    .bdd-logo{
        width: 8%;
        float: left;
        margin-left: 30px;
    }
}

.nav-bar-items{
    display: inline-block;
    width: 85%;
    text-align: right !important;
    .nav-bar-item{
        width: 7% !important;
        text-align: center;
    }
    .nav-bar-item:hover,.ant-menu-item-selected{
        color: #ffffff !important;
        border-bottom: #1C37E4 !important;
    }
}

.ant-menu-submenu-title{
    .user-image{
        width: 40px !important;
        height: 40px !important;
        border-radius: 50%;
        margin-right: 10px;
    }
}
.ant-menu-item, .ant-menu-submenu {
    top: 7px !important;
}

.ant-menu-submenu:hover,
.ant-menu-submenu-active,
.ant-menu-submenu-open,
.ant-menu-submenu-title:hover,
.ant-menu-submenu-selected{
    color: #FFFFFF !important;
    border-bottom: #1C37E4 !important;
}
