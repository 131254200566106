@import "src/styles/variables";

.ride-tracker-form__container {
  width: 60vw !important;
  height: 600px !important;
  .ant-modal-content {
    height: 100% !important;
    border-radius: 18px;
  }
  .ant-modal-body {
    text-align: center;
    padding: 3rem !important;
  }
  .ride-tracker-form__content {
    text-align: start;
  }
  .ride-tracker-form__title {
    font-size: 24px;
    font-weight: bold;
    color: #1c37e4;
  }
  .ride-tracker-form__field {
    margin-top: 2rem;
    .label {
      margin-bottom: 1rem;
      color: #000000;
      font-size: 14px;
    }
    .ant-select {
      width: 100% !important;
      background-color: #f6f6f6 !important;
      border: none !important;
      font-weight: bold !important;
      color: black !important;
      box-shadow: none !important;
      height: 50px !important;
      .ant-select-selector {
          margin-top: 10px;
          background-color: #f6f6f6 !important;
          border: none !important;
          box-shadow: none !important;
      }
    }

    .ride-form__picker {
      width: 100%;
      input {
        background-color: #f6f6f6;
        font-weight: bold;
        color: black;
      }
      box-shadow: none;
      height: 50px;
      background-color: #f6f6f6;
      border: none;
      .ant-picker-suffix {
        span {
          color: #1c37e4;
        }
      }
    }
  }
  .no-of-times__slider,
  .no-of-times__slider:hover {
    margin-top: 2rem !important;
    .ant-slider-handle,
    .ant-slider-track {
      background: #0cbd12 !important;
      border: unset;
    }
  }
  .ride-tracker__rating {
    margin-left: 5%;
    .anticon-star {
      font-size: 50px !important;
    }
  }
  .ride-tracker-form__close {
    font-size: 35px;
    position: absolute;
    bottom: -50px;
    right: 0;
    left: 0;
    color: #ff4444;
    cursor: pointer;
    background: white;
    width: 35px;
    border-radius: 50%;
    margin: auto;
  }
  .ride-tracker-form__actions {
    float: right;
    button {
      margin: 2rem 1rem;
    }
  }
}
