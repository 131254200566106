@import "src/styles/variables";

.recent-blogs{
  margin: 30px auto;
}

.landing-page__footer{
  background: $primary-color;
  padding: 15px 0;
  color: #ffffff;
}

.landing-page__footer__content{
  width: 80%;
  margin: 0 auto;
}

.landing-page__footer__left{
  display: inline-block;
  width: 33%;
}

.landing-page__footer__center{
  display: inline-block;
  width: 33%;
  text-align: center;
  img{
    cursor: pointer;
    width: 10%;
    margin-right: 10px;
  }
}

.landing-page__footer__right{
  display: inline-block;
  width: 33%;
  text-align: right;
  span{
    margin-left: 35px;
    cursor: pointer;
  }
}

.landing-page__footer__mobile{
  display: none;
}

@media (max-width: 896px){
  .landing-page{
    overflow-x: hidden;
  }

  .recent-blogs,.landing-page__footer__content{
    display: none;
  }

  .landing-page__footer{
    background: transparent;
  }

  .landing-page__footer__mobile{
    display: block;
    width: 100%;
    margin:40px auto;
    text-align: center;
    img{
      width: 50%;
    }
  }
  .landing-page__footer__download{
    margin-top: 50px;
    width: 85%;
    height: 50px !important;
    line-height: 45px !important;
  }
}
