@import 'src/styles/variables';

.rides__card{
  display: inline-block;
  vertical-align: top;
  width: 32%;
  margin: 20px 2% 20px 0;
  border-radius: 0px;
  box-shadow: 0 12px 24px #00000014;
  padding: 10px 10px 0 10px;
  cursor: pointer;
  &:nth-child(3n){
    margin-right: 0;
  }
}
.ride-wait-time__card {
  padding: 5px 10px;
  color: white;
  border-radius: 5px;
}
.ride-wait__green {
  background: #0cbd12;
}
.ride-wait__blue {
  background: #1c37e4;
}
.ride-wait__red {
  background: #e41c1c;
}

.rides__card__image{
  img{
    width: 100%;
    height: 300px;
    object-fit: cover;
    border-radius: 12px;
  }
}

.rides__card__content{
  width: 80%;
  margin: auto;
}

.rides__card__name{
  margin-top: 20px;
  font-weight: bold;
  font-size: 16px;
  color: #000000;
}

.rides__card__type{
  color:$primary-color;
  font-style: italic;
  font-size: 13px;
}

.rides__card__location,.rides__card__wait{
  font-size: 12px;
  font-weight: 500;
  color: #000000;
  .anticon{
    display: inline-block;
    font-size: 14px;
    width: 25px;
    height: 25px;
    text-align: center;
    line-height: 30px;
    color:#ffffff;
    border-radius: 4px;
    background: #AA29CB;
    margin: 10px 10px 0 0;
  }
}

.rides__card__wait{
  .anticon{
    background: $secondary-color;
  }
}

.rides__card__footer{
  margin-bottom: 20px;
}

.rides__card__wait{
  display: inline-block;
  vertical-align: top;
  width: 70%;
}

.rides__card__action{
  display: inline-block;
  width: 30%;
  vertical-align: bottom;
  text-align: right;
  margin-top: 15px;
  .ant-btn-primary{
    padding: 5px 17px;
    border-radius: 5px;
  }
}

.change-plan-button,.change-plan-button:hover{
  background: #E4791C !important;
  color: #FFFFFF !important;
}

.default-plan-info{
  margin-top: 15px;
  color: $app-primary-green;
  font-size: 12px;
}
