.manage-groups-users{
  width: 80% !important;
}

.manage-groups__details-card{
  position: relative;
  display: inline-block;
  width: 32%;
  vertical-align: top;
  margin: 30px 2% 0 0;
  box-shadow: 0 12px 25px #00000014;
  text-align: center;
  color: #000000;
  padding: 30px 30px 15px 30px;
  cursor: pointer;
  &:nth-child(3n){
    margin-right: 0;
  }
  .manage-groups__details-card--name{
    position: relative;
    font-weight: 600;
    font-size: 16px;
  }
  .manage-groups__details-card--count{
    position: relative;
    font-size: 13px;
  }
  .manage-groups__details-card--members{
    position: relative;
    margin: 10px 0;
    img{
      margin-right: 3.5%;
      width: 15%;
      border-radius: 50%;
      &:last-child{
        margin-right: 0;
      }
    }
    .image-overlay{
      position: absolute;
      width: 15%;
      right: 15px;
      top:0;
      bottom: 0;
      margin: auto;
      background: #00000090;
      border-radius: 50%;
      color: #ffffff;
      .count{
        margin-top:10px;
      }
    }
  }
  .manage-groups__details-card--created{
    color:#E4791C;
    text-align: right;
    font-size: 10px;
  }
}

.manage-groups__invites{
  display: inline-block;
  width: 32%;
  margin: 25px 2% 0 0;
  vertical-align: top;
  background: #F6F6F6;
  border-radius: 3px;
  padding: 10px 20px;
  &:nth-child(3n){
    margin-right: 0;
  }
  .manage-groups__invites--image{
    display: inline-block;
    width: 12%;
    margin-right: 3%;
    vertical-align: middle;
    border-radius: 50%;
  }
  .manage-groups__invites--user-details{
    display: inline-block;
    width: 70%;
    vertical-align: middle;
    .name{
      font-size: 14px;
      font-weight: 600;
    }
    .description{
      font-size: 12px;
    }
  }
  .manage-groups__invites--actions{
    text-align: right;
    margin-top: 20px;
    .ant-btn{
      font-size: 12px;
    }
    .ant-btn-text{
      color:#B4B4B4;
    }
    .ant-btn-primary{
      background: #0CBD12 !important;
    }
  }
  .manage-groups__invites--status{
    color:#E4791C;
    font-size: 12px;
    display: inline-block;
    width: 13%;
    vertical-align: bottom;
  }
  .manage-groups__members--action{
    font-size: 25px;
    transform: rotate(90deg);
    vertical-align: middle;
    color: #D5D5D5;
    margin-left: 5%;
  }
}

.new-group-modal {
  width: 25% !important;
  .new-group-modal__title {
    font-size: 25px;
    font-weight: bold;
    color: #000000;
    margin: 40px 0 15px 0;
  }
  .ant-modal-content{
    border-radius: 18px;
  }
  .ant-modal-body{
    padding:30px 40px;
  }
  .new-group-modal__input{
    margin: 10px 0;
    background: #F6F6F6;
    border-radius: 3px;
    outline: unset;
    border: unset;
    font-weight: bold;
  }
  .new-group-modal__action{
    width: 100% !important;
    margin: 20px 0 40px 0;
  }
  .new-group-modal__close{
    font-size: 35px;
    position: absolute;
    bottom: -40px;
    right: 0;
    left: 0;
    color:#FF4444;
    cursor: pointer;
    background: white;
    width: 35px;
    border-radius: 50%;
    margin: auto;
  }
}

.manage-groups-users{
  width: 80%;
  margin: 20px auto;
}

.member-form{
  width: 60%;
  margin: 40px auto;
  .member-form__title{
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 2rem;
  }
  .member-form__field{
    display: inline-block;
    width: 48%;
    vertical-align: top;
    margin: 15px 2% 15px 0;
    .ant-input,.ant-select{
      width: 75% !important;
      margin-top: 10px;
    }
    .ant-input,.ant-select-selector{
      background: #F6F6F6;
      border-radius: 3px;
      border: unset;
      height: auto;
    }
    .ant-select-selection-item{
      background: #1CE4C9;
      border-radius: 13px;
      color: #ffffff;
      .anticon{
        color: #ffffff;
      }
    }
    .height-slider{
      width: 72.5%;
    }
    .height-input{
      width: 25%;
      margin-left: 0 !important;
    }
  }
  .member-form__checkbox {
    margin-bottom: 15px;
  }
  .member-form__actions{
    text-align: right;
    margin-top: 20px;
    .ant-btn{
      padding: 5px 30px;
      font-size: 12px;
    }
  }
  .attached-image{
    width:20%;
  }
  .upload-button{
    margin: 10px auto;
    width: 20%;

  }
}

.manage-groups__details--action{
  position: absolute;
  top: 5px;
  right: 15px;
  font-size: 30px;
  color: #D5D5D5 !important;
  z-index: 1;
}

.ant-dropdown-menu-item{
  font-size: 12px !important;
  .anticon{
    font-size: 14px !important;
    margin-right: 5px !important;
  }
}
