.manage-plans-card{
  position: relative;
  display: inline-block;
  width: 23%;
  vertical-align: top;
  margin: 40px 2% 0 0;
  padding: 20px 25px;
  &:nth-child(4n){
    margin-right: 0;
  }
  box-shadow: 0 12px 25px #00000014;
  border-radius: 0px;
}

.manage-plans-card__action{
  position: absolute;
  right: 10px;
  top:10px;
  font-size: 24px;
  color:#AAAAAA;
}

.manage-plans-card__name{
  color:#000000;
  font-size: 18px;
  margin: 15px 0 10px 0 ;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.manage-plans-card__field{
  margin: 15px 0;
  font-size: 13px;
  color: #000000;
}

.icon-date,.icon-plan-name,.icon-rides{
  display: inline-block;
  text-align: center;
  height: 25px;
  width:25px;
  border-radius: 3px;
  margin: 0 10px 0 0;
  color: #ffffff;
  padding: 4px 3px;
}

.icon-date{
  background:#E4791C;
}

.icon-plan-name{
  background: #0CBD12;
}

.icon-rides{
  background: #1C37E4;
}
