@import "src/styles/variables";

.rain-gear-shops{
  width: 80%;
  margin: 35px auto 20px;
}

.rain-gear-shops__title{
  font-size: 22px;
}

.rain-gear-shops__list{
  margin: 15px 0;
}

.rain-gear-shops__list__title{
  color: $primary-color;
  font-size: 12px;
  margin-bottom: 20px;
  font-weight: 500;
}

.rain-gear-shops__name{
  display: inline-block;
  vertical-align: top;
  width: 33%;
  color:#656565;
  font-size: 14px;
  margin-bottom: 15px;
  cursor: pointer;
  span{
    background: $primary-color;
    color:#ffffff;
    padding: 3px 8px;
    margin-right: 10px;
    font-size: 12px;
    border-radius: 2px;
  }
}
