@import 'src/styles/variables';

.update-default-plan-modal{
  width: 375px !important;
  .ant-modal-content{
    border-radius: 18px;
  }
  .ant-modal-close{
    left:0;
    top: unset;
    margin: auto;
    bottom: -60px;
  }
  .ant-modal-close-x{
    background: #FF4444;
    color: #ffffff;
    border-radius: 50%;
    width: 45px;
    height: 45px;
    line-height: 46px;
    font-size: 20px;
    &:hover{
      color: #ffffff;
    }
  }
}

.update-default-plan__text{
  font-weight: 700;
  font-size: 20px;
  color: $primary-color;
  margin-bottom: 35px;
}

.update-default-plan__select{
  width: 60%;
  margin: 15px auto;
};

.update-default-plan__select__name{
  color: #313131 !important;
  font-size: 14px !important;
  font-weight: 600;
  margin: 5px 0 !important;
  width: 100%;
}

.update-default-plan__actions{
  text-align: center;
  margin-top: 20px;
  .ant-btn-primary{
    width: 100%;
    background: $primary-color !important;
    color:#ffffff !important;
  }
}
