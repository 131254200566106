@import "src/styles/variables";

.ride-tracker__content {
  padding: 50px 50px 70px 50px !important;
  width: 100% !important;
}
.add-completed-ride__btn {
  background-color: #1C37E4 !important;
  color: #fff !important;
  border: none !important;
  height: 40px !important;
  float: right;
}
.ride-tracker {
  width: 80%;
  margin: 40px auto;
}

.total-rides-count__container {
  display: flex;
  align-items: center;
  padding-top: 4rem;
  .total-rides-count__title {
    font-size: 24px;
    font-weight: 500;
  }
  .total-rides__count {
    margin-left: 1rem;
    font-size: 16px;
    font-weight: 600;
    background-color: #0cbd12;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    border-radius: 24px;
  }
}
.ride-tracker__card {
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 32%;
  margin: 15px 2% 15px 0;
  background: #f6f6f6;
  border-radius: 0px;
  padding: 10px;
  cursor: pointer;
  &:nth-child(3n) {
    margin-right: 0;
  }
}


.ride-tracker__image {
  display: inline-block;
  vertical-align: middle;
  width: 30%;
  margin-right: 4%;
  img {
    width: 100%;
    height: 150px;
    object-fit: cover;
    border-radius: 4px;
  }
}

.ride-tracker__details {
  display: inline-block;
  vertical-align: middle;
  width: 65%;
  color: #000000;
}

.ride-tracker__name {
  white-space: pre-wrap;
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 10px;
}

.ride-tracker__location,
.ride-tracker__park {
  font-size: 12px;
  margin-bottom: 10px;
}

.ride-tracker__location {
  .icon-location {
    font-size: 12px;
    color: #ffffff;
    background: #aa29cb;
    padding: 5px 7px;
    margin-right: 10px;
    border-radius: 4px;
  }
}

.ride-tracker__park {
  img {
    height: 22px;
    width: 22px;
    margin-right: 10px;
  }
}

.ride-tracker__count {
  position: absolute;
  display: inline-block;
  right: 20px;
  top: 20px;
  color: #ffffff;
  background: $app-primary-green;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-size: 13px;
}

.ride-tracker__year {
  display: inline-block;
  vertical-align: top;
  width: 24%;
  margin: 10px 1.33% 30px 0;
  color: #000000;
  &:nth-child(4n) {
    margin-right: 0;
  }
}

.ride-tracker__year__name {
  font-weight: 700;
  font-size: 16px;
}

.ride-tracker__year__card {
  position: relative;
  margin: 15px auto;
  background: #f6f6f6;
  padding: 15px 20px;
  border-radius: 0px;
}

.ride-tracker__year__date {
  font-size: 13px;
}

.ride-tracker__year__rating {
  .anticon {
    font-size: 14px;
  }
  .ant-rate-star-full,
  .ant-rate-star-active {
    color: #feb712;
  }
  .ant-rate-star-zero,
  .ant-rate-star-half {
    .ant-rate-star-second {
      color: #bababa;
    }
  }
}

.ride-tracker__year__count {
  top: 0;
  bottom: 0;
  margin: auto;
}

.ride-tracker__placeholder {
  text-align: center;
  p {
    margin-bottom: 0 !important;
  }
}
