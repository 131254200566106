@import "src/styles/variables";

.explore{
  position: relative;
  color: #000000;
  margin-top: 200px;
  padding-bottom: 125px;
  background: transparent linear-gradient(180deg, #B9FAAF 0%, #58CFC7 100%) 0 0 no-repeat padding-box;
}

.explore__title{
  color: $primary-color;
  font-size: 40px;
  font-weight: 600;
  margin-left: 12%;
  padding-top: 50px;
}

.explore__row{
  position: relative;
  width: 80%;
  margin: 0 auto 25px auto;
}

.explore__column{
  display: inline-block;
  vertical-align: middle;
  width: 48%;
  margin: 0 1% 75px 1%;
}

.explore__section__section1-icon{
  width: 25%;
  margin: 0 0 10px 10px;
}

.explore__section-header{
  font-size: 30px;
  font-weight: 600;
  margin: 0 0 0 10px;
}

.explore__section-content{
  width: 80%;
  margin: 10px 0 0 10px;
  font-size: 14px;
}

.explore__column__section1-image{
  width: 100%;
  margin-top: -200px;
}

.explore__column__section2-image{
  width: 100%;
}

.explore__section__section2-icon{
  position: absolute;
  top:17%;
  right:43%;
  width: 13%;
  transform: rotateZ(10deg);
}

.plan-column{
  padding-top: 100px;
}

.explore__column__section3-image{
  width: 100%;
}

.theme-column{
  padding-top: 150px;
}

.explore__column__section4-image{
  width: 100%;
}

.preference-column{
  padding: 50px 0 0 40px;
}

.explore__footer-image{
  position: absolute;
  bottom:0;
  width: 100%;
  img{
    width: 100%;
  }
}

.explore__bg-icon1,.explore__bg-icon2,.explore__bg-icon3,.explore__bg-icon4,
.explore__bg-icon5,.explore__bg-icon6,.explore__bg-icon7{
  position: absolute;
  width: 8%;
}

.explore__bg-icon1{
  left: 35%;
  top: 5%;
}

.explore__bg-icon2{
  top: 20%;
  left: 10%;
}

.explore__bg-icon3{
  top: 23%;
  right: 35%;
}

.explore__bg-icon4{
  top: 39%;
  right: 10%;
}

.explore__bg-icon5{
  top: 68%;
  left: 8%;
}

.explore__bg-icon6{
  top: 72%;
  right: 33%;
}

.explore__bg-icon7{
  top: 87%;
  right: 30%;
  width: 6%;
}

.explore__icon{
  position: absolute;
}

.explore__icon1{
  top: 46%;
  left: 10%;
  width: 6%;
}

.explore__icon2{
  top: 46%;
  left: 28%;
  width: 5%;
}

.explore__icon3{
  top: 49%;
  left: 45%;
  width: 5%;
}

.explore__icon4{
  top: 45%;
  right: 35%;
  width: 4%;
}

.explore__icon5{
  top: 48%;
  right: 6%;
  width: 6%;
}

.explore__icon6{
  top: 54%;
  left: 10%;
  width: 5%;
}

.explore__icon7{
  top: 52%;
  left: 28%;
  width: 7%;
}

.explore__icon8{
  top: 62%;
  left: 40%;
  width: 6%;
  transform: rotateZ(-15deg);
}

.explore__icon9{
  top: 70%;
  left: 16%;
  width: 6%;
}

.explore__icon10{
  top: 69%;
  right: 47%;
  width: 6%;
}

.explore__icon11{
  top: 75%;
  right:20%;
  width: 6%;
  transform: rotateZ(20deg);
}

.explore__icon12{
  top: 83%;
  right:48%;
  width: 6%;
}

.explore__icon13{
  top: 83%;
  left:7%;
  width: 6%;
}

.explore__icon14{
  top: 89%;
  left:41%;
  width: 6%;
}

.explore__icon15{
  top: 88%;
  right: 21%;
  width: 6%;
}

.explore__mobile{
  display: none;
}

@media (max-width: 896px) {
  .explore{
    margin-top: 40px;
  }

  .explore__row{
    position: relative;
    width: 85%;
  }

  .explore__title{
    font-size: 26px;
    margin-bottom: 40px;
    margin-left: 10%;
  }

  .explore__desktop{
    display: none;
  }

  .explore__mobile{
    display: block;
  }

  .explore__section-header{
    font-size: 20px;
  }
  .explore__section-content{
    position: relative;
    font-size: 16px;
    width: 100%;
    margin-bottom: 10px;
    z-index: 1;
  }
  .explore__column__section1-image{
    position: relative;
    margin-top: 0;
  }
  .explore__section__section1-icon{
    position: absolute;
    top:40%;
    z-index: 1;
  }
  .explore__section__section2-icon{
    width: 15%;
    top:-5%;
    right:0;
    z-index: 1;
  }

  .explore__column__section1-image,
  .explore__column__section2-image,
  .explore__column__section3-image,
  .explore__column__section4-image
  {
    position: relative;
    margin-bottom: 40px;
    z-index: 1;
  }

  .explore__column__section4-image{
    margin-bottom: 0;
  }

  .explore__bg-icon1{
    width: 30%;
    top:13.25%;
    left:5%;
    z-index: 0;
  }
  .explore__bg-icon2{
    width: 20%;
    top:3%;
    right:5%;
    left: unset;
  }
  .explore__bg-icon3{
    width: 25%;
    top:27.5%;
    right:7.5%;
    left: unset;
    z-index: 0;
  }
  .explore__bg-icon4{
    width: 22%;
    top:35%;
    right:2%;
    left: unset;
    z-index: 0;
    transform: rotateZ(40deg);
  }
  .explore__bg-icon5{
    width: 25%;
    top:unset;
    bottom:2.5%;
    left: 20%;
    z-index: 0;
  }
  .explore__bg-icon6{
   display: none;
  }
  .explore__bg-icon7{
    width: 17%;
    top:unset;
    bottom:25%;
    right: 5%;
    left: unset;
    z-index: 0;
  }
  .explore__icon2{
    width: 12%;
    top:51%;
    right: 5%;
    left: unset;
  }
  .explore__icon4{
    width: 15%;
    top:34%;
    right: 5%;
  }
  .explore__icon7{
    width: 14%;
    top:61%;
    left: 6%;
    z-index: 1;
  }
  .explore__icon9{
    width: 15%;
    top:unset;
    bottom: 25.5%;
    right: 18%;
    left: unset;
    z-index: 1;
  }
  .explore__icon10{
    width: 15%;
    top:unset;
    bottom: 12%;
    right: 2%;
    left: unset;
  }
  .explore__icon11{
    width: 15%;
    top:unset;
    bottom: 17%;
    right: 6%;
    left: unset;
    z-index: 1;
  }
  .explore__icon13{
    width: 18%;
    top:unset;
    bottom: 5%;
    right: 5%;
    left: unset;
    z-index: 1;
  }
  .explore__icon1,
  .explore__icon3,
  .explore__icon5,
  .explore__icon6,
  .explore__icon8,
  .explore__icon12,
  .explore__icon14,
  .explore__icon15{
    display: none;
  }
}
